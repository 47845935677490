.media {
	display: flex;
	flex-direction: column;
}

.media--horizontal {
	@media (--xlarge-viewport) {
		flex-direction: row;
	}
}

.media__visual {
	margin: 0 0 var(--length-m) 0;

	text-align: center;

	.media--horizontal & {
		@media (--xlarge-viewport) {
			margin: 0 var(--length-m) 0 0;

			text-align: left;
		}
	}
}

.media__body {
	text-align: center;

	.media--horizontal & {
		@media (--xlarge-viewport) {
			text-align: left;
		}
	}
}

.media__title {
	margin: 0;
	font-weight: bold;

	font-size: var(--font-size-s);
}

.media__text {
	margin: 0;
}

.media__icon {
	width: 3rem;
	height: 3rem;

	.media--horizontal & {
		@media (--medium-viewport) {
			width: 5rem;
			height: 5rem;
		}
	}
}
