.placeholder {
	margin: var(--length-m);

	text-align: center;
}

.placeholder__icon {
	width: 6rem;
	height: 6rem;
	margin: 0 0 var(--length-m);

	color: var(--color-grey-dark);
}

.placeholder__content {
	font-size: var(--font-size-m);
}
