.navigation__list {
	display: flex;

	margin: 0;
	padding: 0;

	list-style-type: none;

	@media (--large-viewport) {
		flex-direction: column;
	}
}

.navigation__item {
	text-align: center;
}

.navigation__icon {
	color: var(--color-secondary);
}

.navigation__link {
	display: block;

	padding: var(--length-m);

	text-decoration: none;

	&:focus,
	&:hover {
		outline: none;
		background-color: var(--color-grey-light);
	}
}

.navigation__label {
	display: block;

	margin: var(--length-xs) 0 0;

	color: var(--color-secondary-light);
	font-size: var(--font-size-xxs);
}
