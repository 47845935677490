@font-face {
	font-style: normal;

	font-weight: normal;
	src: url('/fonts/wigrum-regular.woff2') format('woff2');
	font-family: 'Wigrum Web';
}

@font-face {
	font-style: normal;

	font-weight: bold;
	src: url('/fonts/wigrum-bold.woff2') format('woff2');
	font-family: 'Wigrum Web';
}

@font-face {
	font-style: italic;

	font-weight: normal;
	src: url('/fonts/wigrum-italic.woff2') format('woff2');
	font-family: 'Wigrum Web';
}

@font-face {
	font-style: italic;

	font-weight: bold;
	src: url('/fonts/wigrum-bold-italic.woff2') format('woff2');
	font-family: 'Wigrum Web';
}

@font-face {
	font-style: normal;

	font-weight: normal;
	src: url('/fonts/inter-regular.woff2') format('woff2');
	font-family: 'Inter Web';
}

@font-face {
	font-style: normal;

	font-weight: bold;
	src: url('/fonts/inter-bold.woff2') format('woff2');
	font-family: 'Inter Web';
}

@font-face {
	font-style: italic;

	font-weight: normal;
	src: url('/fonts/inter-italic.woff2') format('woff2');
	font-family: 'Inter Web';
}

@font-face {
	font-style: italic;

	font-weight: bold;
	src: url('/fonts/inter-bold-italic.woff2') format('woff2');
	font-family: 'Inter Web';
}

:root {
	--font-family-wigrum: 'Wigrum Web', sans-serif;

	--font-family-inter: 'Inter Web', sans-serif;

	--font-size-xxs: 0.75rem;

	--font-size-xs: 0.875rem;

	--font-size-s: 1rem;

	--font-size-m: 1.125rem;

	--font-size-l: 1.315rem;

	--font-size-xl: 2.25rem;

	--font-size-xxl: 3rem;
}
