/* Credit: https://tinyurl.com/ohwmw5q */

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}
