.icon,
.icon--m {
	width: var(--icon);
	height: var(--icon);

	vertical-align: middle;

	fill: currentColor;
}

.icon--s {
	width: var(--icon-s);
	height: var(--icon-s);
}

.icon--l {
	width: var(--icon-l);
	height: var(--icon-l);
}
