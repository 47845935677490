.dashboard {
	display: grid;
	grid-template:
		'logo' max-content
		'header' max-content
		'body' 1fr
		'sidebar' max-content;
	height: 100vh;

	overflow: hidden;

	background-color: var(--color-surface);

	@media (--medium-viewport) {
		grid-template:
			'logo header' max-content
			'sidebar body' 1fr
			/ max-content 1fr;
	}
}

.dashboard__logo {
	display: flex;
	grid-area: logo;
	align-items: center;
	justify-content: center;
}

.dashboard__header {
	display: flex;
	grid-area: header;
	align-items: baseline;
	justify-content: space-between;

	padding: var(--length-s);

	border-bottom: var(--border-m) solid var(--color-grey);

	@media (--medium-viewport) {
		border: none;
	}
}

.dashboard__sidebar {
	display: flex;

	grid-area: sidebar;
	justify-content: space-between;

	padding: 0 var(--length-m) 0 0;

	border-top: var(--border-m) solid var(--color-grey);

	@media (--medium-viewport) {
		flex-direction: column;

		padding: var(--length-s) 0;

		border: none;
	}
}

.dashboard__actions {
	align-self: center;
}

.dashboard__body {
	grid-area: body;
	padding: var(--length-s);

	overflow-y: auto;

	background-color: var(--color-grey-light);

	scroll-behavior: smooth;
}
