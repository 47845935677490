.notification {
	display: none;
	z-index: 30;
	position: fixed;
	top: var(--length-s);
	left: 50%;

	padding: var(--length-s) var(--length-m);

	transform: translate3d(-50%, 0, 0);
	border-radius: var(--length-xxs);

	background: var(--color-grey);
	box-shadow: var(--shadow-l);

	color: var(--color-on-surface);

	&.is-visible {
		display: block;
	}
}

.notification--info {
	background-color: var(--color-tertiary);
	color: var(--color-surface);
}

.notification--error {
	background-color: var(--color-error);
	color: var(--color-surface);
}

.notification--warning {
	background-color: var(--color-warning);
}
