/* Only display content to screen readers */
/* See: http://a11yproject.com/posts/how-to-hide-content/ */

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;

	overflow: hidden;

	border: none;

	clip: rect(0, 0, 0, 0);
}
