.table-wrapper {
	width: 100%;
	padding: var(--length-m);
	overflow-x: auto;
	border-radius: var(--length-xs);

	background-color: var(--color-surface);

	& table {
		margin-bottom: 0;
	}

	&:not(:last-child) {
		margin: 0 0 var(--length-s);
	}

	&:empty {
		display: none;
	}
}
