.form {
	display: flex;
	flex-direction: column;

	min-height: 100%;
}

.form__fields {
	flex: 1 1 auto;

	@media (--xlarge-viewport) {
		display: grid;

		grid-gap: var(--length-s);

		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}
