.authentication {
	display: grid;

	grid-gap: var(--length-l);

	@media (--large-viewport) {
		grid-template-columns: 2fr 3fr;
	}

	margin: var(--length-l) 0;
}

.authentication__header {
	display: flex;
	grid-column: 1 / -1;
	justify-content: space-between;
}

.authentication__subheader {
	grid-column: 1 / -1;
}

.authentication__footer {
	grid-column: 1 / -1;
}
