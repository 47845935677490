form {
	width: 100%;
}

fieldset {
	margin: 0;
	padding: 0;

	border: none;
}

textarea {
	display: block;

	width: 100%;
	height: 14rem;
}

input,
select,
textarea {
	color: inherit;
	font-size: var(--length-m);
	font-family: inherit;

	&[disabled] {
		background: var(--color-grey-light);
		color: var(--color-grey-dark);
	}

	&[readonly] {
		color: var(--color-grey-dark);
	}

	&::placeholder {
		color: var(--color-grey-dark);
	}
}
