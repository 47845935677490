.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	padding: calc(var(--length-xs) + var(--border-m)) var(--length-m);
	gap: var(--length-s);
	border: 0;
	border-radius: var(--length-xs);

	background: var(--color-grey);

	color: inherit;
	font-weight: bold;
	font-size: var(--font-size-s);
	font-family: inherit;
	text-decoration: none;

	transition:
		background-color 0.2s,
		color 0.2s;

	&:focus {
		outline: var(--border-m) solid var(--color-tertiary);
		outline-offset: var(--border-m);
	}

	&:hover {
		background: var(--color-grey-dark);
	}

	&[disabled] {
		&,
		&:hover {
			background-color: var(--color-grey-light);
			color: var(--color-grey-dark);
		}
	}

	& svg {
		pointer-events: none;
	}

	&.is-loading {
		transition: none;

		&,
		&:focus,
		&:hover {
			color: transparent;
		}

		&:after {
			@extend %spinner;
		}
	}
}

.button--primary {
	background-color: var(--color-primary);
	color: var(--color-surface);

	&:hover {
		background-color: var(--color-primary-light);
		color: var(--color-on-surface);
	}
}

.button--secondary {
	background-color: var(--color-secondary-dark);
	color: var(--color-surface);

	&:hover {
		background-color: var(--color-secondary);
	}
}

.button--light {
	background-color: transparent;
	color: var(--color-on-surface);

	&:hover {
		background-color: var(--color-grey);
	}
}

.button--large {
	padding: var(--length-m) var(--length-l);

	font-size: var(--font-size-m);
}

.button--icon-only {
	padding: var(--length-s);

	border-radius: 50%;

	&.button--large {
		padding: var(--length-m);
	}

	& svg {
		display: block;
		top: 0;

		margin-right: 0;
	}
}

.button--fluid {
	width: 100%;
}

.button--animate {
	& .icon {
		transition: transform 2s;
	}

	&:hover {
		& .icon {
			transform: rotate(360deg);
		}
	}
}
