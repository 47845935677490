.field-group {
	display: flex;
	flex-direction: column;
	align-items: baseline;

	margin-bottom: var(--length-s);

	overflow-x: auto;

	@media (--medium-viewport) {
		flex-direction: row;

		& .field {
			margin-bottom: 0;

			& + .field {
				margin-left: var(--length-s);
			}
		}
	}
}

.field-group__buttons {
	display: flex;
	align-self: start;

	@media (--medium-viewport) {
		margin-top: 1.6rem;
	}

	& .button {
		margin-left: var(--length-s);
	}
}
