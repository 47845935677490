.form-state {
	display: flex;
	position: relative;
	align-items: center;
}

.form-state__body {
	margin: 0 var(--length-m) 0 0;

	&:empty {
		display: none;
	}
}

.form-state__actions {
	margin: 0 var(--length-m) 0 0;
}

.form-state__mask {
	width: var(--length-m);
	height: var(--length-m);
}
