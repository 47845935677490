.box {
	padding: var(--length-m) var(--length-l);
	border-radius: var(--length-m);

	background: var(--color-grey-light);

	text-align: left;

	@media (--xlarge-viewport) {
		padding: var(--length-l);
	}
}

.box--secondary {
	background-color: var(--color-secondary);
}

.box--tertiary {
	background-color: var(--color-tertiary-light);
}

.box--surface {
	background-color: var(--color-surface);
}

.box__title {
	font-size: var(--font-size-l);

	@media (--medium-viewport) {
		margin: 0 0 var(--length-l);
	}
}
