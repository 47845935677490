.image-list {
	display: grid;
	grid-template: 1fr 1fr / 2fr 3fr;
	grid-gap: var(--length-m);

	/* max-width: 55vw; */

	margin: 0 auto;

	@media (--small-viewport) {
		grid-gap: 0 var(--length-l);
		grid-template: 2fr 1fr / 3fr 3fr 4fr 4fr;

		/* max-width: 60rem; */
	}

	@media (--large-viewport) {
		/* max-width: 65rem; */
	}
}

.image-list__item {
	@media (--small-viewport) {
		&:nth-child(1) {
			order: 2;
		}

		&:nth-child(2) {
			order: 3;
		}

		&:nth-child(3) {
			order: 1;
		}

		&:nth-child(4) {
			order: 4;
		}

		&:nth-child(1),
		&:nth-child(2) {
			grid-row: span 2;
		}
	}
}

.image-list__visual {
	width: 100%;
	height: 100%;
	object-fit: cover;

	border-radius: var(--length-m);
}
