:root {
	--border-s: 1px;
	--border-m: 2px;
	--border-l: 4px;

	--icon: 1rem;
	--icon-s: 0.75rem;
	--icon-l: 1.25rem;

	--length-xxs: 0.125rem;
	--length-xs: 0.25rem;
	--length-s: 0.5rem;
	--length-m: 1rem;
	--length-l: 2rem;
	--length-xl: 3rem;
	--length-xxl: 4rem;

	--input-control-size: 1.25rem;
	--button-size: 2.25rem;
}
