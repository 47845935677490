.fieldset__group {
	margin: calc(var(--length-s) * -1);
	margin-bottom: var(--length-s);

	padding: var(--length-s);
	border: var(--border-m) solid var(--color-grey);
	border-radius: var(--length-xs);

	background: var(--color-surface);
}

.fieldset {
	min-width: auto; /* https: //tinyurl.com/yawb4jmu; */

	margin: 0 0 var(--length-s);
	padding: var(--length-m);
	border-radius: var(--length-xs);

	background: var(--color-surface);

	& .fieldset {
		margin: 0 calc(var(--length-s) * -1) var(--length-s);
		padding: var(--length-s);
		border-radius: 0;

		background-color: rgb(196, 196, 196, 0.15);

		& .fieldset__group {
			margin: calc(var(--length-s) * -1);
		}

		&:last-child {
			margin-bottom: 0;
		}

		& .fieldset {
			margin: 0 calc(var(--length-s) * -1) calc(var(--length-s) * -1);
		}
	}
}

.fieldset__legend {
	width: 100%;
	margin: 0 0 var(--length-m);
	padding: 0;
	float: left;

	font-weight: bold;

	& + * {
		clear: both;
	}
}
