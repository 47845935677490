%field-control {
	padding: var(--length-xs) var(--length-s);
	border: var(--border-m) solid var(--color-grey);
	border-radius: var(--length-xs);

	outline: none;
	background-color: var(--color-surface);

	font-size: var(--font-size-s);

	transition: border-color 0.2s;

	&:hover {
		border-color: var(--color-grey-dark);
	}

	&:focus {
		border-color: var(--color-tertiary);
	}

	&.is-invalid {
		border-color: var(--color-error);
		box-shadow: none; /* Overwrite FF box-shadow for invalid fields */
	}
}

.field {
	display: flex;
	position: relative;
	flex-direction: column;

	width: 100%;

	&:not(:last-child) {
		margin-bottom: var(--length-m);
	}
}

.field--inline {
	display: inline-flex;
	flex-direction: row;

	width: auto;
	margin-bottom: 0;

	&,
	&:not(:last-child) {
		margin-bottom: 0;
	}
}

.field--list {
	display: block;
}

.field__label {
	display: inline-block;

	padding: 0 0 var(--length-xs);

	color: var(--color-secondary-light);
	font-size: var(--font-size-xs);
}

.field__control {
	@extend %field-control;

	.field--inline & {
		margin-right: var(--length-s);
	}

	.has-errors & {
		border-color: var(--color-error);
		box-shadow: none; /* Overwrite FF box-shadow for invalid fields */
	}
}

.field__button {
	.is-invalid + & {
		border: var(--border-m) solid var(--color-error);
	}
}

.field__list {
	@extend %field-control;
	height: 18.4rem;

	padding: 0 var(--length-s);

	overflow-y: auto;

	background-color: var(--color-surface);

	& .field__control {
		z-index: 1;
		position: sticky;
		top: var(--length-s);

		width: 100%;
		margin: 0 0 var(--length-m);

		box-shadow: 0 -20px 0 var(--color-surface);
	}
}

.field__error {
	color: var(--color-error);
	font-size: var(--font-size-xs);
}

.field__error-note {
	align-self: flex-end;

	color: var(--color-error);
	font-size: var(--font-size-xs);
}
