.toggle-switch {
	display: inline-block;

	width: calc(var(--input-control-size) * 2);
	height: var(--input-control-size);
}

.toggle-switch__input {
	position: absolute;
	opacity: 0;

	&:checked + label {
		background-color: var(--color-tertiary);

		&:after {
			transform: translate3d(100%, 0, 0) scale(0.75);

			background-color: var(--color-surface);

			transition: all 0.2s;
		}
	}

	&:focus + label {
		outline: var(--border-m) solid var(--color-tertiary);
		outline-offset: var(--border-m);
	}

	&:hover + label {
		&:after {
			background-color: var(--color-secondary);
		}
	}

	&:active + label {
		background-color: var(--color-grey-dark);

		&:after {
			background-color: var(--color-surface);
		}
	}

	&:disabled + label,
	&:disabled:active + label {
		background-color: var(--color-grey-light);

		&:after {
			background-color: var(--color-grey);
		}
	}

	&:disabled:checked + label {
		&:after {
			background-color: var(--color-surface);
		}
	}
}

.toggle-switch__label {
	display: block;
	position: relative;

	width: inherit;
	height: inherit;
	margin: 0;
	border-radius: var(--length-m);

	background-color: var(--color-grey);

	transition: transform 0.2s;

	&:after {
		position: absolute;

		width: var(--input-control-size);
		height: inherit;

		transform: scale(0.75);
		border-radius: inherit;

		background-color: var(--color-secondary-light);
		content: '';

		transition: inherit;
	}
}
