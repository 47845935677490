h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 var(--length-m);
	font-weight: bold;

	font-family: var(--font-family-wigrum);
}

h1 {
	font-size: var(--font-size-xxl);
}

h2 {
	font-size: var(--font-size-xl);
}

h3 {
	font-size: var(--font-size-l);
}

h4 {
	font-size: var(--font-size-m);
}

h5,
h6 {
	font-size: var(--font-size-s);
}

p {
	margin: 0 0 var(--length-m);
}

a {
	color: var(--color-tertiary);

	transition: color 0.2s;

	&:hover {
		text-decoration: none;
	}
}
