table {
	width: 100%;
	margin: 0 0 var(--length-s);

	border-collapse: collapse;
}

th,
td {
	padding: var(--length-s) var(--length-m);

	white-space: nowrap;

	& a {
		text-decoration: none;

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}
}

th {
	font-weight: bold;
	text-align: left;
}

thead {
	& tr {
		border-bottom: var(--border-m) solid var(--color-grey);
	}

	&,
	& a {
		color: var(--color-secondary-light);
	}
}

tbody {
	& tr {
		&:focus,
		&:hover {
			background-color: var(--color-tertiary-light);
		}

		&:not(:last-child) {
			border-bottom: var(--border-m) solid var(--color-grey-light);
		}
	}
}
