.breadcrumbs {
	display: flex;

	margin: 0;
	padding: 0;

	font-size: var(--font-size-xs);

	list-style: none;

	@media (--medium-viewport) {
		font-size: var(--font-size-s);
	}
}

.breadcrumbs__item {
	&:not(:last-child) {
		&:after {
			padding: 0 var(--length-xs);

			content: '/';
		}
	}
}
