.modal {
	display: flex;

	z-index: 20;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	align-items: center;
	justify-content: center;

	padding: var(--length-m);

	background: rgba(0, 0, 0, 0.4);

	&.is-visible {
		transform: scale(1);

		opacity: 1;

		transition: opacity 0.2s;
	}

	&.is-hidden {
		transform: scale(0);
		opacity: 0;

		pointer-events: none;
	}
}

.modal__wrapper {
	position: relative;

	width: 100%;
	max-width: 100%;
	padding: var(--length-m);
	border-radius: var(--length-m);

	background: var(--color-surface);
	box-shadow: var(--shadow-l);

	transition: transform 0.4s;

	.is-hidden & {
		transform: translate3d(0, 3rem, 0);
	}

	.is-visible & {
		transform: translate3d(0, 0, 0);
	}

	@media (--medium-viewport) {
		width: 40rem;

		.modal--fluid & {
			width: auto;
			min-width: 40rem;
		}
	}
}

.modal__header {
	margin-right: var(--length-l);
	padding: 0 var(--length-s);

	font-size: var(--font-size-m);

	@media (--small-viewport) {
		font-size: var(--font-size-l);
	}
}

.modal__body {
	max-height: 70vh;

	padding: 0 var(--length-m);
	overflow-y: auto;
}

.modal__close {
	position: absolute;
	top: var(--length-s);
	right: var(--length-s);
}
