.card {
	position: relative;

	margin: 0 auto;
	padding: var(--length-l);

	border-radius: var(--length-m);
	background-image: url('/images/rings-s.svg'), url('/images/rings-xl.svg');
	background-position:
		top left,
		bottom right;
	background-size: 20vw, 40vw;
	background-repeat: no-repeat;

	background-color: var(--color-primary);

	color: var(--color-surface);

	@media (--medium-viewport) {
		padding: calc(var(--length-xxl) * 2) var(--length-xxl);
	}

	@media (--xlarge-viewport) {
		background-size: 7vw, 20vw;
	}
}

.card--secondary {
	background-color: var(--color-secondary-dark);
}

.card__title {
	font-size: var(--font-size-l);

	@media (--medium-viewport) {
		font-size: var(--font-size-xl);
	}
}

.card__subtitle {
	display: block;

	color: var(--color-grey-dark);
	font-weight: normal;
	font-size: var(--font-size-m);

	& span {
		color: var(--color-primary);
	}
}

.card__body {
	margin: 0 0 var(--length-m);

	@media (--small-viewport) {
		max-width: 60%;
	}

	@media (--medium-viewport) {
		font-size: var(--font-size-m);
	}
}

.card__visual {
	display: none;

	@media (--large-viewport) {
		display: block;
		position: absolute;
		right: var(--length-xxl);
		bottom: 0;

		max-height: 80%;
	}
}
