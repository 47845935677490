.action-bar {
	display: flex;
	z-index: 10;
	position: sticky;
	bottom: 0;
	align-items: center;
	justify-content: space-between;

	height: 3rem;

	padding: var(--length-s);
	border-top: var(--border-m) solid var(--color-grey);

	background: rgba(255, 255, 255, 0.95);

	& > * {
		margin-bottom: 0;
	}
}

.action-bar--flush {
	padding-right: 0;
	padding-left: 0;
}
