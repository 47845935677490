.load-mask {
	display: flex;

	z-index: 30;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	min-height: var(--length-m);

	background-color: rgba(255, 255, 255, 0.9);

	&:after {
		@extend %spinner;
	}
}
