.pagination {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
}

.pagination__total {
	margin: 0 var(--length-xs) 0 0;

	font-weight: bold;
}

.pagination__items {
	display: flex;
}

.pagination__item {
	padding: var(--length-xs) var(--length-s);

	&:focus,
	&:hover {
		color: var(--color-secondary);
	}

	&.is-active {
		border-radius: var(--length-xs);

		background-color: var(--color-grey-light);

		color: var(--color-on-surface);
		text-decoration: none;
	}

	&.is-disabled {
		color: var(--color-grey);
	}
}
