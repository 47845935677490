.list-group {
	margin: 0;
	padding: 0;

	list-style: none;
}

.list-group__item {
	padding: var(--length-s) 0;

	&:not(:last-child) {
		border-bottom: var(--border-m) solid var(--color-grey-light);
	}
}

.list-group__icon {
	margin-right: var(--length-m);
}
