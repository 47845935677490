html,
body {
	height: 100%;

	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;

	background: var(--color-surface);

	color: var(--color-on-surface);
	font-size: var(--font-size-s);
	font-family: var(--font-family-wigrum);
}

::-webkit-scrollbar {
	width: var(--length-m);
	height: var(--length-m);
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
	background: var(--color-grey-light);
}

::-webkit-scrollbar-thumb {
	background: var(--color-grey-dark);
}
