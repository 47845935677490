.content-wrapper {
	display: flex;

	flex-grow: 1;
	align-items: center;
	justify-content: center;

	min-height: 100%;
}

.content-wrapper__body {
	max-width: 50rem;
	margin: 0 0 10vh;
	padding: 0 0 var(--length-m);
}
