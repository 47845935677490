%spinner {
	position: absolute;

	width: var(--length-m);
	height: var(--length-m);

	border: var(--border-m) solid var(--color-secondary);
	border-radius: 100%;

	border-top-color: transparent;

	content: '';

	animation: rotate 0.6s linear infinite;
}
