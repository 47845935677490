.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-primary {
	color: var(--color-primary);
}

.text-secondary {
	color: var(--color-secondary-dark);
}

.text-tertiary {
	color: var(--color-tertiary);
}

.text-muted {
	color: var(--color-grey-dark);
}

.text-info {
	color: var(--color-info);
}

.text-error {
	color: var(--color-error);
}

.text-warning {
	color: var(--color-warning);
}

.float-right {
	float: right;
}

.font-normal {
	font-weight: normal;
}

.font-bold {
	font-weight: bold;
}
