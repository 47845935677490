.custom-input {
	/* Rounded radio button */
	& input[type='radio'] {
		& + label {
			border-radius: 50%;

			&:before,
			&:after {
				border-radius: inherit;
			}

			&:after {
				width: calc(var(--input-control-size) * 0.5);
				height: calc(var(--input-control-size) * 0.5);
				transform: translate3d(50%, 50%, 0);

				background: var(--color-surface);
			}
		}
	}

	/* Checkmark for checkbox */
	& input[type='checkbox'] {
		& + label {
			&:after {
				width: calc(var(--input-control-size) * 0.3);
				height: calc(var(--input-control-size) * 0.6);
				transform: rotate(45deg) translate3d(65%, -30%, 0);

				border-right: var(--border-m) solid var(--color-tertiary);
				border-bottom: var(--border-m) solid var(--color-tertiary);
			}
		}
	}

	/* Hide native radio/checkbox inputs */
	& input[type='checkbox'],
	& input[type='radio'] {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;

		overflow: hidden;

		clip: rect(0, 0, 0, 0);

		/* Custom input label */
		& + label {
			position: relative;

			height: var(--input-control-size);
			padding: 0 0 var(--length-s)
				calc(var(--input-control-size) + var(--length-s));

			font-size: var(--font-size-s);

			/* Unchecked */
			&:before {
				position: absolute;
				top: var(--length-xxs);
				left: 0;

				width: var(--input-control-size);
				height: var(--input-control-size);

				border: var(--border-m) solid var(--color-grey-dark);
				border-radius: var(--length-xs);
				background-color: var(--color-surface);

				content: '';
			}

			/* Checked */
			&:after {
				position: absolute;
				top: var(--length-xs);
				left: var(--length-xxs);

				content: '';

				opacity: 0;

				transition: all 0.2s;
			}
		}

		/* Checked state */
		&:checked + label {
			&:before {
				border-color: var(--color-tertiary);
				background-color: var(--color-tertiary-light);
			}

			&:after {
				opacity: 1;
			}
		}

		/* Focus state */
		&:focus + label {
			&:before {
				outline: var(--border-m) solid var(--color-tertiary);
				outline-offset: var(--border-m);
			}
		}

		/* Hover state */
		&:hover + label {
			&:before {
				border-color: var(--color-secondary-light);
			}
		}

		/* Active state */
		&:active + label {
			&:before {
				border-color: transparent;
				background-color: var(--color-grey);
			}
		}

		/* Invalid state */
		&.is-invalid + label {
			&:before {
				border-color: var(--color-error);
			}
		}

		/* Disabled state */
		&:disabled + label {
			color: var(--color-grey-dark);

			&:before {
				border-color: var(--color-grey-dark);
				background-color: transparent;
			}
		}

		/* Disabled + checked state */
		&:disabled:checked + label {
			&:before {
				border-color: transparent;
				background-color: var(--color-grey);
			}
		}
	}
}
