:root {
	--color-grey: #e5e5e5;
	--color-grey-light: #f3f4f5;
	--color-grey-dark: #c4c4c4;

	--color-primary: #fb5f61;
	--color-primary-light: #fdbabc;
	--color-primary-dark: #fa3c41;

	--color-secondary: #3a4152;
	--color-secondary-light: #6a717e;
	--color-secondary-dark: #212a3f;

	--color-tertiary: #16aaf4;
	--color-tertiary-light: #f2f9ff;

	--color-surface: #fff;
	--color-on-surface: var(--color-secondary-dark);

	--color-info: var(--color-tertiary);
	--color-error: #f00;
	--color-warning: #f8ad1a;
}
