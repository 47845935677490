.label {
	display: inline-block;

	margin: var(--length-xs) var(--length-xs) 0 0;
	padding: var(--length-xs) var(--length-s);
	border: none;
	border-radius: var(--length-xs);

	background-color: transparent;

	color: var(--color-grey-dark);
	font-size: var(--font-size-xs);
	font-family: inherit;

	transition: all 0.2s;

	&:focus,
	&:hover {
		outline: none;

		background: var(--color-grey);
		color: var(--color-on-surface);
	}

	&.is-active {
		background: var(--color-tertiary-light);
		color: var(--color-tertiary);

		&:focus,
		&:hover {
			background: var(--color-tertiary);
			color: var(--color-tertiary-light);
		}
	}
}

.label-container {
	display: flex;
	flex-wrap: wrap;
}
